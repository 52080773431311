<template>
    <div class="home">
        <CarrotHeader></CarrotHeader>
        <div class="sub-wrap">
            <div class="sub-wrap-title">
                <p>전자결재</p>
            </div>
            


            <!-- 서브페이지 내용 -->
            <div class="con-wrap">
                <CarrotTitle title="전체 경비관리">
                    - 본부/팀 별 전체 경비를 확인 할 수 있습니다. 
                    <a class="btn-default float-right mt-10" href="/documents/AllStatisticsList">전체 통계</a>
                </CarrotTitle>
                
                <div class="board">
                    <div class="mt-40">
                        <div>
                            <table class="table-row table-serach">
                                <tbody>
                                    <tr>
                                        <th width="180">기간</th>
                                        <td>
                                            <CarrotDatePicker v-model="comp.search.sdate"></CarrotDatePicker> ~ <CarrotDatePicker v-model="comp.search.edate"></CarrotDatePicker>
                                        </td>
                                        <th width="180">매니저</th>
                                        <td>
                                            <CarrotStaff :idx_office = "comp.search.idx_office" :idx_team = "comp.search.idx_team" v-model="comp.search.idx_staff"></CarrotStaff>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="180">본부</th>
                                        <td>

                                           <CarrotSector v-model="comp.search.idx_sector" ></CarrotSector>
                                        </td>
                                        <th width="180">팀</th>
                                        <td>
                                            <CarrotTeam v-model="comp.search.idx_team" :idx_sector="comp.search.idx_sector"></CarrotTeam>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <button class="btn-default float-right mt-10" @click="comp.find()">검색</button>
                            <div class="clear"></div>
                            
                            <div class="block-title mt-50 mb-20">개인+법인</div>
                            <table class="table-col" v-if="comp.indiv!=null && comp.corp!=null">
                                <colgroup>
                                    <col span="5">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>교통비(합계)</th>
                                        <th>식대</th>
                                        <th>유류비</th>
                                        <th>기타</th>
                                        <th class="total">총합계</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{comp.numberToText(comp.indiv.expense + comp.corp.expense)}}</td>
                                        <td>{{comp.numberToText(comp.indiv.meals + comp.corp.meals)}}</td>
                                        <td>{{comp.numberToText(comp.indiv.fuel + comp.corp.fuel)}}</td>
                                        <td>{{comp.numberToText(comp.indiv.etc + comp.corp.etc)}}</td>
                                        <td class="total">{{comp.numberToText(comp.indiv.total + comp.corp.total)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            
                            <div class="block-title mt-50 mb-20">개인</div>
                            <table class="table-col" v-if="comp.corp!=null">
                                <colgroup>
                                    <col span="5">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>교통비(합계)</th>
                                        <th>식대</th>
                                        <th>유류비</th>
                                        <th>기타</th>
                                        <th class="total">총합계</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{comp.numberToText(comp.corp.expense)}}</td>
                                        <td>{{comp.numberToText(comp.corp.meals)}}</td>
                                        <td>{{comp.numberToText(comp.corp.fuel)}}</td>
                                        <td>{{comp.numberToText(comp.corp.etc)}}</td>
                                        <td class="total">{{comp.numberToText(comp.corp.total)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            
                            <div class="block-title mt-50 mb-20">법인</div>
                            <table class="table-col" v-if="comp.indiv!=null">
                                <colgroup>
                                    <col span="5">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>교통비(합계)</th>
                                        <th>식대</th>
                                        <th>유류비</th>
                                        <th>기타</th>
                                        <th class="total">총합계</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{comp.numberToText(comp.indiv.expense)}}</td>
                                        <td>{{comp.numberToText(comp.indiv.meals)}}</td>
                                        <td>{{comp.numberToText(comp.indiv.fuel)}}</td>
                                        <td>{{comp.numberToText(comp.indiv.etc)}}</td>
                                        <td class="total">{{comp.numberToText(comp.indiv.total)}}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="block-title mt-50 mb-20">상세내역</div>
                            <div class="mb-20 dp-table w-100per">
                                <select v-model="comp.txt_filter" class="float-left w-110px">
                                    <option value="개인">개인</option>
                                    <option value="법인">법인</option>
                                    <option value="">개인+법인</option>
                                </select>
                                <button class="btn-default float-right h-30px" @click="comp.getExcel">엑셀다운로드</button>
                            </div>                            
                            <table class="table-col">
                                <colgroup>
                                    <col width="60">
                                    <col width="60">
                                    <col width="120">
                                    <col width="120">
                                    <col width="120">
                                    <col width="120">
                                    <col width="120">
                                    <col width="120">
                                    <col width="120">
                                    <col width="120">
                                    <col width="120">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>구분</th>
                                        <th>총합계</th>
                                        <th>교통비(합계)</th>
                                        <th>식대</th>
                                        <th>유류비</th>
                                        <th>기타</th>
                                        <th>매니저</th>
                                        <th>부문</th>
                                        <th>본부</th>
                                        <th>팀</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(d, idx) in comp.list" :key="idx">
                                        <tr v-if="d.cat==comp.txt_filter || comp.txt_filter==''">
                                            <td>{{idx+1}}</td>
                                            <td>{{d.cat}}</td>
                                            <td>{{comp.numberToText(d.total)}}원</td>
                                            <td>{{comp.numberToText(d.expense)}}원</td>
                                            <td>{{comp.numberToText(d.meals)}}원</td>
                                            <td>{{comp.numberToText(d.fuel)}}원</td>
                                            <td>{{comp.numberToText(d.etc)}}원</td>
                                            <td>{{d.staff_ename}} ({{d.staff_kname}})</td>

                                            <td>{{d.sector}}</td>
                                            <td>{{d.office}}</td>
                                            <td>{{d.team}}</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                            <CarrotPaging :total="comp.total" :list_per_page="30" v-model="comp.search.page" @change="comp.find" class="mt-10"></CarrotPaging>

                        </div>
                    </div>
                </div>
            </div>
            <!-- 서브페이지 내용 끝 -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import axios from '@/plugins/axios.js'
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue';

import CarrotSector from '@/components/common/CarrotSector.vue';
import CarrotTeam from '@/components/common/CarrotTeam.vue';
import CarrotStaff from '@/components/common/CarrotStaff.vue';
// import { useStore } from 'vuex'


export default {
    components: {
        CarrotDatePicker,
        CarrotTeam,
        CarrotStaff,
        CarrotSector
    },
    setup() {
        const toast = useToast();
        const router = useRouter();
        // const store = useStore();

        const comp = reactive({
            search : {
                page : 1,
                sdate : "",
                edate : "",
                idx_sector:0,
                idx_office : 0,
                idx_team : 0,
                idx_staff : 0,
            },
            years : [],
            months : [],
            txt_filter : "",
            list : [],
            indiv : null,
            corp : null,

            toStringByFormatting : (source, delimiter = '-') => {
                 const year = source.getFullYear();
                 const month = comp.leftPad(source.getMonth() + 1);
                 const day = comp.leftPad(source.getDate());
                 return [year, month, day].join(delimiter); 
            },
            leftPad : (value) => {
                if (value >= 10) { 
                    return value; 
                } 
                return `0${value}`;
            },

            numberToText : (num) => {
                return Number(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },

            getExcel : () => {
                let params = comp.search;
                let str = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
                location.href="/rest/expense/AllExpenseExcelList?" + str;
            },

            find : () => {
                let param = {params : comp.search};
                axios.get('/rest/expense/AllExpenseList', param).then((res)=>{
                    if( res.data.err == 0 ){
                        comp.list = res.data.list;
                        comp.indiv = res.data.indiv;
                        comp.corp = res.data.corp;
                        comp.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            modify : (idx) => {
                router.push({ 
                    path:"/documents/ExpenseInputModify/" + idx
                });
            },
        });

        onMounted(() => {
            // if(store.state.position!='리드') {
            //     toast.error("리드 이상 접근 가능한 페이지 입니다.");
            //     router.push({ 
            //         path:"/documents/MyExpenseManagement"
            //     });
            //     return;
            // }
            let today = new Date();
            comp.search.sdate = comp.toStringByFormatting(new Date(today.getFullYear(), today.getMonth(), 1));
            comp.search.edate = comp.toStringByFormatting(new Date(today.getFullYear(), today.getMonth()+1, 0));

            comp.find();
            
        });

        return {comp};
    }

}
</script>


<style lang="scss" scoped>
</style>